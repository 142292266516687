.page-title-area {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
}

.page-title-area::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #030821;
    left: 0;
    top: 0;
    opacity: 0.5;
    z-index: -1;
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table-cell {
    vertical-align: middle;
}

.page-title-content {
    text-align: center;
    margin-top: 45px;
}

.page-title-content h2 {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 42px;
    font-weight: 700;
}

.page-title-content ul {
    padding-left: 0;
    list-style-type: none;
    margin-top: 12px;
    margin-bottom: 0;
}

.page-title-content ul li {
    color: #ffffff;
    display: inline-block;
    position: relative;
    font-weight: 400;
    font-size: 17px;
    margin-left: 10px;
    margin-right: 10px;
}

.page-title-content ul li::before {
    content: '';
    position: absolute;
    right: -15px;
    top: 11px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #7b68ee;
}

.page-title-content ul li:last-child:before {
    content: '';
    display: none;
}

.page-title-content ul li a {
    display: inline-block;
    color: #ffffff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.page-title-content ul li {
    color: #ffffff;
    display: inline-block;
    position: relative;
    font-weight: 400;
    font-size: 17px;
    margin-left: 10px;
    margin-right: 10px;
}