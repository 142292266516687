.services-section .section-title {
    margin-bottom: 35px;
}

.section-title h6 {
    font-size: 16px;
    font-weight: 700;
    color: #388e3c;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.section-title h2 {
    font-size: 40px;
    color: #0b0b31;
    margin: 10px 0 0 0;
    font-weight: 800;
}

.single-services-item {
    position: relative;
    z-index: 1;
    display: block;
    padding: 35px 30px;
    text-align: left;
    border-radius: 3px;
    background-color: #ffffff;
    margin-top: 30px;
    -webkit-box-shadow: 5px 7px 15px 2px rgb(82 90 101 / 12%);
    -moz-box-shadow: 5px 7px 15px 2px rgba(82, 90, 101, 0.12);
    box-shadow: 5px 7px 15px 2px rgb(82 90 101 / 12%);
    -webkit-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
    will-change: transform;
}

.services-icon {
    display: inline-block;
    margin-bottom: 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    will-change: transform;
}

.single-services-item h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
    -webkit-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
}

.single-services-item p {
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 0;
    -webkit-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
    will-change: transform;
}

.single-services-item .services-btn-link {
    margin-top: 16px;
}

.single-services-item .services-btn-link .services-link {
    display: inline-block;
    font-size: 16px;
    color: #388e3c;
    letter-spacing: 0.5px;
    font-weight: 700;
    -webkit-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
    text-decoration: underline;
    font-family: 'Dosis', sans-serif;
}

.services-icon img {
    width: 100px;
}
.single-services-item:hover .services-icon{
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}