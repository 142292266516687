.about-area {
    position: relative;
    z-index: 1;
}

.about-content h6 {
    color: #388e3c;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.about-content h2 {
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 20px;
}

.about-content-text p {
    margin-bottom: 15px;
}

.about-area .skills {
    margin-top: 30px;
}

.about-area .skills .skill-item {
    margin-bottom: 25px;
}

.about-area .skills .skill-item h6 {
    position: relative;
    z-index: 4;
    font-size: 15px;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 10px;
}

.about-area .skills .skill-item h6 em {
    float: right;
    font-size: 15px;
    font-style: normal;
}

.about-area .skills .skill-item .skill-progress {
    position: relative;
    height: 5px;
    border-radius: 5px;
    background: #f1f1fa;
}

.about-area .skills .skill-item .skill-progress .progres {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10%;
    background-color: #388e3c;
    -webkit-transition: all 1.5s;
    transition: all 1.5s;
}

.about-btn-box {
    margin-top: 35px;
    display: inline-block;
}

.about-image {
    position: relative;
    text-align: center;
    margin-left: 0px;
}

.about-image img {
    border-radius: 3px;
}

.about-image .years-design {
    position: absolute;
    left: -75px;
    bottom: 25px;
    text-align: left;
    padding: 20px 40px 20px 40px;
    background: #ffffff;
    border-bottom: 2px solid #FF9800;
    -webkit-box-shadow: -1px 3px 20px 0px rgb(82 90 101 / 10%);
    box-shadow: -1px 3px 20px 0px rgb(82 90 101 / 10%);
}

.about-image .years-design h2 {
    font-size: 70px;
    font-weight: 700;
    background: url("https://cutesolution.com/html/Techvio/assets/img/slider-2.jpg") no-repeat;
    background-position: 47% 53%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.1;
}

.about-image .years-design h5 {
    color: #FF9800;
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
    text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-content {
        margin-top: 40px;
    }
    .about-content-text h2 {
        font-size: 33px;
    }
    .about-image {
        text-align: center;
        margin-top: 35px;
    }
    .about-image .years-design {
        left: 75px;
        bottom: 25px;
    }
}

@media only screen and (max-width: 767px) {
    .about-content-text h2 {
        font-size: 25px;
    }
}