.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: transparent;
    top: 0;
    left: 0;
}

.preloader.preloader-deactivate {
    visibility: hidden;
}

.preloader::before,
.preloader::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 60%;
    height: 100%;
    z-index: -1;
    background: #7b68ee;
    -webkit-transition: .9s;
    transition: .9s;
}

.preloader.preloader-deactivate::after,
.preloader.preloader-deactivate::before {
    width: 0;
}

.preloader.preloader-deactivate .loader {
    opacity: 0;
    visibility: hidden;
}

.preloader .loader {
    position: absolute;
    top: 43%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-43%);
    transform: translateY(-43%);
    text-align: center;
    margin: 0 auto;
    width: 50px;
    height: 50px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.preloader .loader .shadow {
    width: 100%;
    height: 5px;
    background: #000000;
    opacity: 0.1;
    position: absolute;
    top: 59px;
    left: 0;
    border-radius: 50%;
    -webkit-animation: shadow .5s linear infinite;
    animation: shadow .5s linear infinite;
}

.preloader .loader .box {
    width: 100%;
    height: 100%;
    background: #ffffff;
    -webkit-animation: animate .5s linear infinite;
    animation: animate .5s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
}

.preloader.preloader-deactivate::after,
.preloader.preloader-deactivate::before {
    width: 0;
}

.preloader::after {
    left: auto;
    right: 0;
}

.preloader::before,
.preloader::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 60%;
    height: 100%;
    z-index: -1;
    background: #7b68ee;
    -webkit-transition: .9s;
    transition: .9s;
}

.navbar-area {
    /* background-color: transparent; */
    background: linear-gradient(to bottom, #cfcdd1, #d3d3d4, #ffffff, #ffffff, #ffffff);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    position: absolute;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
}

.techvio-responsive-nav {
    display: none;
}

.techvio-nav {
    /* background: linear-gradient(to bottom, #370b6f, #00429b, #006dba, #0095ce, #2dbcdc); */
    background: linear-gradient(to bottom, #cfcdd1, #d3d3d4, #ffffff, #ffffff, #ffffff);
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 0;
    padding-left: 0;
}

nav {
    background-color: transparent;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 0;
    padding-left: 0;
}

.techvio-nav .navbar ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
}

.techvio-nav .navbar .navbar-nav {
    margin: auto;
}

.techvio-nav .navbar .navbar-nav .nav-item {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0;
    padding-right: 0;
}

.techvio-nav .navbar .navbar-nav .nav-item .dropdown-menu {
    -webkit-box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
    background: #ffffff;
    position: absolute;
    border: none;
    top: 80px;
    left: 0;
    width: 230px;
    z-index: 99;
    display: block;
    opacity: 0;
    visibility: hidden;
    border-radius: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 0px;
}

.techvio-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
    padding: 0;
    border-bottom: 1px solid #f1f1f1;
}

.techvio-nav .navbar .navbar-nav .nav-item a {
    font-size: 16px;
    font-weight: 600;
    color: #388e3c;
    /* text-transform: capitalize; */
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
}

.techvio-nav .navbar .navbar-nav .nav-item:first-child a {
    margin-left: 0;
}

.techvio-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
    text-transform: capitalize;
    padding: 10px 15px;
    margin: 0;
    position: relative;
    color: #0b0b31;
    font-size: 15.5px;
    font-weight: 500;
}

.techvio-nav .navbar .navbar-nav .nav-item .dropdown-menu li:last-child {
    border-bottom: 0px solid transparent;
}

.techvio-nav .navbar .other-option {
    margin-left: 0;
    margin-top: 5px;
    display: flex;
    align-items: center;
}
.techvio-nav .navbar .other-option a{
    color: #000;
    font-size: 18px;
    margin-right: 10px;
}

/* .navbar-area.is-sticky .other-option a{
    color: #000;
} */
.default-btn {
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: #ffffff;
    background-color: #388e3c;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 3px;
    font-weight: 600;
    font-size: 15px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 12px;
    padding-bottom: 12px;
    box-shadow: 0 7px 25px rgb(123 104 238 / 25%);
}

.default-btn span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    /* background-color: #3e57d0; */
    background-color: #c0c0c2;
    -webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 30px;
}

.default-btn:hover,
.default-btn:focus {
    color: #ffffff;
}

.default-btn:hover span,
.default-btn:focus span {
    width: 225%;
    height: 562.5px;
}

.techvio-nav .navbar .navbar-nav .nav-item a:hover {
    text-decoration: none;
    color: #373738;
    background: transparent;
}

.techvio-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.techvio-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.techvio-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.techvio-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
    color: #373738;
    background: #f9f9f9;
}

.navbar-area.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    -webkit-box-shadow: 0 2px 28px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 2px 28px 0 rgb(0 0 0 / 6%);
    background: #ffffff !important;
    -webkit-animation: 500ms ease-in-out 0s normal fadeInDown;
    animation: 500ms ease-in-out 0s normal fadeInDown;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.navbar-area.is-sticky .navbar-nav .nav-item a {
    color: #388e3c;
}

.navbar-area.is-sticky .techvio-nav .navbar-brand .white-logo {
    display: none;
}

.navbar-area.is-sticky .techvio-nav .navbar-brand .black-logo {
    display: block;
}

.techvio-nav .black-logo {
    display: none;
}

.mean-container a.meanmenu-reveal {
    width: 35px;
    height: 30px;
    position: absolute;
    right: 0;
    cursor: pointer;
    color: #000000;
    text-decoration: none;
    font-size: 16px;
    text-indent: -9999em;
    line-height: 22px;
    font-size: 1px;
    display: block;
    font-weight: 700;
    padding-top: 5px;
}

todo.mean-container .mean-bar,
.mean-container .mean-bar * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

a.meanmenu-reveal {
    display: none;
}

.mean-container a.meanmenu-reveal {
    width: 35px;
    height: 30px;
    position: absolute;
    right: 0;
    cursor: pointer;
    color: #000000;
    text-decoration: none;
    font-size: 16px;
    text-indent: -9999em;
    line-height: 22px;
    font-size: 1px;
    display: block;
    font-weight: 700;
    padding-top: 5px;
}

.mean-container a.meanmenu-reveal span {
    display: block;
    background: #000000;
    height: 4px;
    margin-top: 3px;
    border-radius: 3px;
}

.mean-container .mean-nav {
    float: left;
    width: 100%;
    background: #ffffff;
    margin-top: 48px;
    display: none;
}

.mean-container .mean-nav.mean-nav-open {
    display: block;
    padding: 0;
}

.mean-container .mean-nav ul {
    padding: 0;
    margin: 0;
    width: 100%;
    border: none;
    list-style-type: none;
}

.mean-container .mean-nav ul li {
    position: relative;
    float: left;
    width: 100%;
}

.mean-container .mean-nav ul li a {
    display: block;
    float: left;
    width: 90%;
    padding: 1em 5% 10px;
    margin: 0;
    text-align: left;
    color: #677294;
    border-top: 1px solid #DBEEFD;
    text-decoration: none;
}

.mean-container .mean-nav ul {
    padding: 0;
    margin: 0;
    width: 100%;
    border: none;
    list-style-type: none;
}

.mean-container .mean-nav ul li {
    position: relative;
    float: left;
    width: 100%;
}

.mean-container .mean-bar {
    float: left;
    width: 100%;
    position: absolute;
    background: transparent;
    padding: 0 0 0;
    z-index: 999;
}

.techvio-nav .navbar .navbar-nav .nav-item a svg {
    font-size: 13px;
    padding-left: 4px;
}

.mean-container .mean-nav ul li li a {
    width: 80%;
    padding: 1em 10% 10px;
    color: #677294;
    border-top: 1px solid #DBEEFD;
    opacity: 1;
    filter: alpha(opacity=75);
    text-shadow: none !important;
    visibility: visible;
    text-transform: none;
    font-size: 14px;
}

.techvio-nav .navbar .navbar-nav .nav-item a.mean-expand {
    display: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .navbar-light .navbar-brand {
        margin-left: 0;
        margin-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        position: relative;
        top: -5px;
        left: -10px;
    }
    .techvio-nav .navbar .navbar-nav .nav-item a {
        font-size: 15px;
    }
}

@media only screen and (max-width: 991px) {
    .techvio-nav {
        display: none;
    }
    .techvio-responsive-nav {
        display: block;
    }
    .techvio-responsive-nav .techvio-responsive-menu {
        position: relative;
    }
    .navbar-area {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .techvio-responsive-nav .techvio-responsive-menu.mean-container .mean-nav ul {
        font-size: 15px;
    }
    .techvio-responsive-nav .techvio-responsive-menu.mean-container .navbar-nav {
        overflow-y: scroll;
        height: 300px;
        -webkit-box-shadow: 0 7px 13px 0 rgb(0 0 0 / 10%);
        box-shadow: 0 7px 13px 0 rgb(0 0 0 / 10%);
    }
    .techvio-responsive-nav .techvio-responsive-menu.mean-container .mean-nav ul li a {
        font-size: 15px;
        color: #000
    }
    .techvio-responsive-nav .techvio-responsive-menu.mean-container .mean-nav ul {
        font-size: 15px;
    }
    .techvio-responsive-nav .logo {
        position: relative;
        padding-top: 2px;
        z-index: 999;
    }
    .techvio-responsive-nav .logo .white-logo {
        display: block;
    }
    .techvio-responsive-nav .logo .black-logo {
        display: none;
    }
    .navbar-area.is-sticky .logo .white-logo {
        display: none;
    }
    .navbar-area.is-sticky .logo .black-logo {
        display: block;
    }
    .techvio-responsive-nav .mean-container a.meanmenu-reveal {
        color: #0b0b31;
        font-size: 18px;
        text-indent: initial;
        text-align: right;
    }
    .techvio-responsive-nav .mean-container a.meanmenu-reveal span {
        background: #0b0b31;
    }
    .techvio-responsive-nav .mean-container a.meanmenu-reveal {
        padding-top: 0;
        color: #fff;
        right: 20px;
        top: 20px;
    }
    .techvio-responsive-nav .mean-container a.meanmenu-reveal span {
        background: #fff;
    }
    .navbar-area.is-sticky .techvio-responsive-nav .mean-container a.meanmenu-reveal span {
        background: #000000;
    }
    .navbar-area.is-sticky .techvio-responsive-nav .mean-container a.meanmenu-reveal {
        padding-top: 6px;
        color: #000000;
    }
    .techvio-responsive-nav .techvio-responsive-menu.mean-container .mean-nav ul li a svg {
        display: none
    }
    /* .techvio-responsive-nav .techvio-responsive-menu.mean-container .mean-nav ul.dropdown-menu {
        display: block;
    } */
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-area {
        -webkit-box-shadow: 0 5px 40px 0 rgb(0 0 0 / 11%);
        box-shadow: 0 5px 40px 0 rgb(0 0 0 / 11%);
    }
    .techvio-responsive-nav .mean-container a.meanmenu-reveal {
        padding-top: 6px;
        color: #fff;
    }
    .mean-container .mean-nav {
        margin-top: 43px;
    }
    .other-option {
        display: none;
    }
    .techvio-responsive-nav .mean-container a.meanmenu-reveal span {
        background: #fff;
    }
    .navbar-area.is-sticky .techvio-responsive-nav .mean-container a.meanmenu-reveal {
        padding-top: 6px;
        color: #000000;
    }
    .navbar-area.is-sticky .techvio-responsive-nav .mean-container a.meanmenu-reveal span {
        background: #000000;
    }
}

@media only screen and (max-width: 1199px) {
    .techvio-responsive-nav .techvio-responsive-menu.mean-container .mean-nav ul.dropdown-menu {
        display: none;
    }
    .techvio-responsive-nav .techvio-responsive-menu.mean-container .mean-nav ul li.active ul.dropdown-menu {
        display: block !important;
    }
    .mean-container .mean-nav ul li a.mean-expand {
        margin-top: 1px;
        width: 100%;
        height: 24px;
        padding: 12px !important;
        text-align: right;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        font-weight: 700;
        background: transparent;
        border: none !important;
        display: block !important;
    }
}
.logo{
    width: 130px;
}