@font-face {
  font-family: 'UTM Avo';
  src: url('../UTM_Avo.ttf') format("truetype");
}

body {
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-family: 'UTM Avo', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  background-color: #e8f5e9;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.4;
  font-family: 'UTM Avo', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.section-padding {
  padding: 100px 0;
}

a {
  text-decoration: none;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  outline: 0 !important;
  color: #0b0b31;
}

.about-component.row {
  margin: 0;
}

.section-padding {
  padding: 100px 0;
}

.section-title {
  text-align: center;
  margin-bottom: 65px;
  line-height: 1.5;
}

.bg-grey {
  background-color: #fafbfc;
}

.pt-70 {
  padding-top: 70px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-50 {
  padding-bottom: 50px;
}

dl,
ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

p {
  font-size: 15px;
  line-height: 1.8;
  color: #5f5f5f;
  margin-bottom: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  font-family: 'UTM Avo', sans-serif;
}

a:hover {
  text-decoration: none;
  color: #7b68ee;
}

.go-top {
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 15px;
  color: #ffffff;
  background-color: #7b68ee;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .9s;
  transition: .9s;
  border-radius: 50%;
}

.go-top.active {
  top: 98%;
  -webkit-transform: translateY(-98%);
  transform: translateY(-98%);
  opacity: 1;
  visibility: visible;
}

.go-top::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #0b0b31;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.go-top:hover::before,
.go-top:focus::before {
  opacity: 1;
  visibility: visible;
}

.go-top:hover i:first-child,
.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}