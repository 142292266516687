.blog-item {
    border-radius: 3px;
    overflow: hidden;
    -webkit-box-shadow: -1px 5px 20px 0px rgb(82 90 101 / 10%);
    box-shadow: -1px 5px 20px 0px rgb(82 90 101 / 10%);
    background-color: #ffffff;
    position: relative;
    margin-bottom: 30px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.blog-item .single-blog-item {
    border-top: none;
    padding: 20px 25px;
}

.blog-item .single-blog-item .blog-list {
    padding-left: 0;
    margin-bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.blog-item .single-blog-item .blog-list li {
    list-style-type: none;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    margin-right: 15px;
    color: #7b68ee;
}

.blog-item .single-blog-item .blog-list a {
    color: #7b68ee;
    text-decoration: none;
}

.blog-item .single-blog-item .blog-list li i {
    margin-right: 3px;
    position: relative;
    top: -1px;
}

.blog-item .single-blog-item .blog-content {
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.blog-item .single-blog-item .blog-content h3 {
    color: #0b0b31;
    font-size: 20px;
    margin: 10px 0px 10px 0;
    line-height: 25px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    line-height: 32px;
}

.blog-item .single-blog-item .blog-content p {
    margin: 0;
    font-size: 15px;
    color: #6a6c72;
    font-weight: 400;
}

.blog-item .single-blog-item .blog-content .blog-btn {
    margin-top: 15px;
}

.blog-item .single-blog-item .blog-content .blog-btn .blog-btn-one {
    display: inline-block;
    font-size: 16px;
    color: #5f5f5f;
    font-weight: 600;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.blog-item:hover .blog-content .blog-btn .blog-btn-one {
    color: #7b68ee;
}
.blog-image img{
    max-height: 300px;
    width: 100%;
    object-fit: cover;
}