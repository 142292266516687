.project-area {
    position: relative;
}

.project-area .project-list {
    text-align: center;
    margin-bottom: 25px;
}

.project-area .project-list .nav {
    display: inline-block;
    margin: 0;
    position: relative;
}

.project-area .project-list .nav li {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0 5px;
    padding: 10px 25px;
    background-color: #fff;
    cursor: pointer;
    border-radius: 0px;
    -webkit-box-shadow: 0px 5px 30px 0px rgb(148 146 245 / 15%);
    box-shadow: 0px 5px 30px 0px rgb(148 146 245 / 15%);
    transition: all 0.4s ease 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    -ms-transition: all 0.5s ease-in-out 0s;
    -o-transition: all 0.5s ease-in-out 0s;
    -webkit-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
}

.project-area .project-list .nav li:hover,
.project-area .project-list .nav li.filter-active {
    color: #fff;
    background-color: #7b68ee;
    -moz-transition: all 0.5s ease-in-out 0s;
    -ms-transition: all 0.5s ease-in-out 0s;
    -o-transition: all 0.5s ease-in-out 0s;
    -webkit-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
}

.project-area .project-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.project-area .project-grid-item {
    overflow: hidden;
    padding: 10px;
}

.project-item {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 0px;
    margin-top: 30px;
}

.project-grid-item img {
    width: 100%;
    border-radius: 0px;
}

.project-item .project-content-overlay {
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: -20px;
    background-color: #fff;
    z-index: 2;
    padding: 20px 20px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.project-item .project-content-overlay p {
    color: #7b68ee;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
    -webkit-transition: all .4s;
    transition: all .4s;
}

.project-item .project-content-overlay h3 {
    color: #1b1b1b;
    font-size: 18px;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
    -webkit-transition: all .4s;
    transition: all .4s;
}

.project-item:hover .project-content-overlay {
    bottom: 10px;
    opacity: 1;
    visibility: visible;
}

.project-item:hover .project-content-overlay p,
.project-item:hover .project-content-overlay h3 {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
}