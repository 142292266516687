.footer-area {
    background: linear-gradient(to bottom, #e7e2e2, #ffffff, #ffffff, #ffffff, #ffffff);
}

.single-footer-widget .footer-heading {
    margin-bottom: 25px;
}

.single-footer-widget .footer-heading h3 {
    font-size: 20px;
    color: #388e3c;
    font-weight: 700;
    margin: 0 0 0 0;
}

.footer-heading h3 {
    font-size: 20px;
    color: #388e3c;
    font-weight: 600;
    margin: 0 0 0 0;
    position: relative;
    border-bottom: 1px solid #777;
    padding-bottom: 10px;
}

.single-footer-widget p {
    font-size: 15px;
    color: #388e3c;
    font-weight: 400;
}

.single-footer-widget .footer-social {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 20px;
}

.single-footer-widget .footer-social li {
    display: inline-block;
    margin-right: 10px;
}

.single-footer-widget .footer-social a {
    display: inline-block;
    height: 35px;
    width: 35px;
    line-height: 35px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50px;
    color: #7b68ee;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    text-align: center;
}

.single-footer-widget .footer-social a:hover {
    background-color: #7b68ee;
    color: #ffffff;
    border: 1px solid #7b68ee;
}

.single-footer-widget .footer-quick-links {
    padding-left: 0;
    margin-bottom: 0;
}

.single-footer-widget .footer-quick-links li {
    list-style-type: none;
    padding-bottom: 18px;
}

.single-footer-widget .footer-quick-links li a {
    display: inline-block;
    color: #388e3c;
    font-size: 15px;
    font-weight: 400;
}

.single-footer-widget .footer-info-contact {
    position: relative;
    padding-left: 35px;
    margin-bottom: 16px;
}

.single-footer-widget .footer-info-contact svg {
    color: #388e3c;
    position: absolute;
    left: 0;
    top: 0px;
    fill: #388e3c;
}

.single-footer-widget .footer-info-contact h3 {
    font-size: 16px;
    color: #388e3c;
    font-weight: 600;
    margin: 0 0 10px 0;
}

.single-footer-widget .footer-info-contact span {
    font-size: 15px;
    color: #388e3c;
    font-weight: 400;
}

.single-footer-widget .footer-info-contact span a {
    font-size: 15px;
    color: #388e3c;
    font-weight: 400;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.copyright-area {
    background-color: #212129;
    padding: 35px 0;
}

.copyright-area p {
    color: #388e3c;
    font-size: 15px;
}

.copyright-area ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    text-align: right;
}

.copyright-area ul li:first-child {
    margin-left: 0;
}

.copyright-area ul li {
    display: inline-block;
    color: #388e3c;
    font-size: 15px;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
}

.copyright-area ul li:first-child::before {
    content: '';
    position: absolute;
    top: 5px;
    right: -13px;
    width: 1px;
    height: 14px;
    background-color: #ffffff;
}

.copyright-area ul li a {
    display: inline-block;
    color: #388e3c;
}

.copyright-area ul li:last-child {
    margin-right: 0;
}

.copyright-area ul li a:hover {
    color: #7b68ee;
}