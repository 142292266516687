.single-team-box {
    margin-top: 30px;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.team-image {
    position: relative;
    z-index: 1;
    border-radius: 3px;
    overflow: hidden;
}

.team-image::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    background: #000;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-team-box .team-image img {
    width: 100%;
    height: auto;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.team-social-icon {
    position: absolute;
    z-index: 5;
    top: 50%;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.team-social-icon a {
    padding: 15px;
    color: #fff;
    font-size: 18px;
    display: inline-block;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    will-change: transform;
}

.team-info {
    padding: 20px 15px 0px;
    text-align: center;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.team-info h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
}

.team-info span {
    color: #5764ec;
    font-size: 15px;
    font-weight: 500;
    display: block;
}

.single-team-box:hover .team-image::before {
    opacity: .8;
    visibility: visible;
}

.team-social-icon {
    position: absolute;
    z-index: 5;
    top: 50%;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.single-team-box:hover .team-social-icon a {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
.team-area .row{
    justify-content: center;
}
.team-info h3{
    margin-bottom: 5px;
}