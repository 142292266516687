.testimonial-section {
    text-align: center;
    position: relative;
    z-index: 1;
}

.single-testimonial {
    position: relative;
    background: #fafafa;
    padding: 30px 15px;
    border-radius: 5px;
}

.single-testimonial .rating-box {
    margin-bottom: 10px;
}

.rating-box ul li {
    display: inline-block;
    margin-right: 0px;
}

.rating-box ul li i {
    color: #ffce39;
    font-size: 16px;
    margin: 0 2px;
}

.single-testimonial .testimonial-content p {
    font-size: 15px;
    color: #333;
    font-style: italic;
    line-height: 1.8;
    letter-spacing: 1px;
}

.single-testimonial .avatar {
    margin: 0 auto;
    margin-bottom: 15px;
}

.single-testimonial .avatar img {
    border-radius: 50%;
    border: 5px solid #ffffff;
    width: 90px;
    margin: 0 auto;
    border-left-color: #7b68ee;
    border-bottom-color: #7b68ee;
}

.single-testimonial .testimonial-bio .bio-info h3 {
    color: #333;
    font-size: 20px;
    font-weight: 700;
    margin-top: 0;
}

.single-testimonial .testimonial-bio .bio-info span {
    color: #333;
    font-size: 15px;
    font-weight: 500;
}

.testimonial-slider .slick-initialized .slick-slide .testimonia-item .single-testimonial {
    margin-right: 20px;
}

@media (max-width: 768px) {
    .testimonial-slider .slick-initialized .slick-slide .testimonia-item .single-testimonial {
        margin-right: 0;
    }
}