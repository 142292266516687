.update div.hide {
    display: none;
}

.update div.show {
    display: block;
}

.update {
    margin-bottom: 30px;
}

.home-area {
    overflow: hidden;
    position: relative;
    z-index: 1;
    height: 800px;
    background: -webkit-gradient(linear, left top, left bottom, from(#370b6f), color-stop(#00429b), color-stop(#006dba), color-stop(#0095ce), to(#2dbcdc));
    background: linear-gradient(to bottom, #370b6f, #00429b, #006dba, #0095ce, #2dbcdc);
}

.home-area.style_2 {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding: 70px 0;
    max-height: 420px;
}

.home-area.style_2::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #03030c;
    opacity: 0.6;
}

.home-area.style_2 .row {
    justify-content: center;
}

.home-area.style_2 h6 {
    font-size: 16px;
    font-weight: 700;
    color: #FF9800;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.home-area.style_2 h1 {
    font-size: 38px;
    color: #fff;
    margin: 0;
    font-weight: 700;
}

.home-area.style_2 p {
    color: #fff;
    font-size: 18px;
    margin: 20px 0 0 0;
}

.home-area.style_2 .banner-btn {
    margin-top: 20px;
}

.home-area.style_2 .banner-btn .default-btn {
    margin-right: 15px;
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table-cell {
    vertical-align: middle;
}

.main-banner-content h1 {
    font-size: 55px;
    color: #000000;
    margin: 0 0 20px 0;
    font-weight: 700;
}

.main-banner-content p {
    color: #0b07ff;
    font-size: 16px;
    margin: 0 0 0 0;
}

.main-banner-content .banner-btn {
    margin-top: 25px;
}

.default-btn-one {
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: #7b68ee;
    background-color: #ffffff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 3px;
    font-weight: 600;
    font-size: 15px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 5px;
    margin-right: 20px;
    box-shadow: 0 7px 25px rgb(123 104 238 / 25%);
}

.default-btn-one span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: #3e57d0;
    -webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 30px;
}

.default-btn-one:hover,
.default-btn-one:focus {
    color: #ffffff;
}

.default-btn-one:hover span,
.default-btn-one:focus span {
    width: 225%;
    height: 562.5px;
}

.home-area .banner-image {
    text-align: center;
    padding-top: 50px;
}

img {
    max-width: 100%;
    height: auto;
    border-radius: 3px;
}

.home-area .creative-shape {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
    text-align: center;
}
.home-area .creative-shape img{
    height: 800px;
    object-fit: cover;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .home-area {
        height: 800px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-area {
        padding-top: 170px;
        padding-bottom: 100px;
        background-position: center;
    }
    .main-banner-content {
        margin: auto;
        text-align: center;
        padding-top: 30px;
    }
    .main-banner-content h1 {
        font-size: 35px;
        margin: 0 0 15px 0;
    }
    .main-banner-content .banner-btn {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .home-area {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 100px;
        background-position: center;
    }
    .main-banner-content {
        text-align: center;
        padding-top: 50px;
    }
    .main-banner-content h1 {
        font-size: 35px;
        margin: 0 0 18px 0;
    }
    .default-btn-one {
        margin-right: 0;
    }
    .home-area.style_2 {
        max-height: 100%;
    }
    .home-area.style_2 .banner-btn .default-btn {
        margin-right: 0;
        margin-left: 10px;
    }
}