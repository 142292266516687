.counter-area {
    text-align: center;
    position: relative;
    color: #fff;
    text-align: center;
    background: url("../../../public/assets/HeroBanner2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: 1;
}

.counter-area::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    opacity: .6;
    background: #fff;
}

.counter-contents h2 {
    color: #388e3c;
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 5px;
    word-spacing: -10px;
}

.counter-contents h3 {
    color: #388e3c;
    font-size: 18px;
    font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-counter {
        padding: 15px 0;
    }
}

@media only screen and (max-width: 767px) {
    .single-counter {
        padding: 15px 0;
    }
}