.services-details-overview:last-child {
    margin-bottom: 0;
}

.services-step-wrapper {
    margin-top: 55px;
}

.services-step-title {
    text-align: left;
    margin-bottom: 40px;
}

.services-step-title h2 {
    font-size: 35px;
    color: #0b0b31;
    font-weight: 700;
}

p {
    font-size: 15px;
    line-height: 1.8;
    color: #5f5f5f;
    margin-bottom: 15px;
    font-weight: 400;
    letter-spacing: 0.3px;
    font-family: 'Catamaran', sans-serif;
}

.features-text {
    margin-bottom: 20px;
}

.features-text h4 {
    font-size: 20px;
}

p:last-child {
    margin-bottom: 0;
}
.services-details-image img{
    margin: 0 auto;
    width: 100%;
    max-height: 500px;
    object-fit: cover;
}