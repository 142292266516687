.contact-info-wrapper {
    padding: 100px 0;
}

.contact-info-wrapper .section-title {
    text-align: center;
    margin-bottom: 45px;
}

.contact-info-content {
    background: #fff;
    margin-top: 30px;
    padding: 45px 20px;
    text-align: center;
    border-radius: 5px;
    border-top: 3px solid #7b68ee;
    -webkit-box-shadow: 0 3px 15px rgb(0 0 0 / 5%), 0 5px 15px rgb(0 0 0 / 3%);
    box-shadow: 0 3px 15px rgb(0 0 0 / 5%), 0 5px 15px rgb(0 0 0 / 3%);
}

.contact-info-content h5 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.contact-info-content p {
    font-size: 15px;
    margin-bottom: 12px;
}

.contact-info-content a {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.5px;
    display: block;
    line-height: 1.5;
}

.contact-info-content a {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.5px;
    display: block;
    line-height: 1.5;
}