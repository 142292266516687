.overview-image {
    text-align: center;
}

.overview-content h6 {
    font-size: 16px;
    font-weight: 700;
    color: #388e3c;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.overview-content h2 {
    font-size: 38px;
    color: #0b0b31;
    margin: 8px 0 15px 0;
}

.overview-content p {
    margin: 0 0 0 0;
}

.overview-content .features-list {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 16px;
    margin-left: -10px;
    margin-right: -10px;
}

.overview-content .features-list li {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.overview-content .features-list li span {
    display: block;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 15px 15px;
    z-index: 1;
    position: relative;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    color: #0b0b31;
    border-left: 2px solid #388e3c;
    font-weight: 600;
    font-size: 18px;
    -webkit-box-shadow: -1px 3px 20px 0px rgb(82 90 101 / 10%);
    box-shadow: -1px 3px 20px 0px rgb(82 90 101 / 10%);
}

.overview-content .features-list li span::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    border-radius: 3px;
    background-color: #388e3c;
    z-index: -1;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.overview-content .features-list li span:hover {
    color: #ffffff;
}

.overview-content .features-list li span:hover::before {
    width: 100%;
}

.overview-section.overview-right .row {
    flex-direction: row-reverse;
}
.overview-content{
    margin-top: 10px;
}

@media only screen and (max-width: 767px) {
    .overview-content h2 {
        font-size: 26px;
        margin: 5px 0 8px 0;
    }
    .overview-content .features-list-1 li,
    .overview-content .features-list li {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .overview-right .row .col-lg-6:first-child {
        order: 2;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .overview-content .features-list li {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
        margin-top: 15px;
        padding-left: 10px;
        padding-right: 10px;
    }
}