.contact-form .form-group {
    margin-bottom: 30px;
}

.contact-form form .form-control {
    background-color: #ffffff;
    border: none;
    height: 55px;
    font-size: 15px;
    border-left: 3px solid #7b68ee;
    -webkit-box-shadow: 0px 5px 30px 0px rgb(148 146 245 / 15%);
    box-shadow: 0px 5px 30px 0px rgb(148 146 245 / 15%);
}
.contact-info-wrapper .row{
    justify-content: center;
}