.App {
  text-align: center;
  margin: 0 auto;
  max-width: 1000px;
  text-align: center;
}
input{
  padding: 8px;
    margin-right: 10px;
}
button{
  background-color: green;
    color: #fff;
    border: none;
    padding: 10px 20px;
}
.App ul{
  margin-top: 40px;
}
.todo__list{
  margin-bottom: 30px;
}

.list-item{
  margin-bottom: 10px;
}
.todo__list button{
  margin-left: 10px;
}
.spinner-container {
  position: absolute;
  top: 3%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}